#app-content {
  display: flex;
}

.router-content {
  width: 100%;
  margin: 2em;
}

.component-frame {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}
