.p-paginator {
  padding-top: .5em;
  padding-bottom: .5em;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  border-radius: 50%;
  margin-left: .25em;
  margin-right: .25em;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: #00095B;
}

.p-paginator-page.p-link:focus {
  box-shadow: unset;
}

/* Styles specific to the table on the Message Center screen */
#message-table .p-datatable .p-datatable-header {
  color: white;
  background-color: #00095B;
  border: 1px solid #00095B;
  padding-left: 0;
  padding-right: 0;
}

.p-datatable .message-column.p-sortable-column .p-sortable-column-icon,
.p-datatable .message-column p-columnfilter .p-column-filter .p-column-filter-menu-button {
  color: unset;
  text-align: unset;
}

.p-datatable .message-column p-columnfilter .p-column-filter .p-column-filter-menu-button {
  font-weight: bolder;
}

.p-datatable .message-column.p-sortable-column.p-highlight,
.p-datatable .message-column.p-sortable-column.p-highlight .p-sortable-column-icon {
  background: unset;
  color: #1700F4;
}

.p-datatable .message-column.p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #1700F4;
}

.p-datatable .message-column.p-sortable-column.p-highlight:hover {
  box-shadow: inset 0 0 0 0.3rem #1700F4;
}

.p-datatable .message-column p-columnfilter .p-column-filter .p-column-filter-menu-button:hover,
.p-datatable .message-column p-columnfilter .p-column-filter .p-column-filter-menu-button:focus,
.p-datatable .message-column p-columnfilter .p-column-filter .p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-datatable .message-column p-columnfilter .p-column-filter .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background-color: #1700F4;
  color: white;
  box-shadow: unset;
  font-weight: normal;
}

.message-table-control p-inputSwitch .p-inputswitch {
  display: block;
}

.message-table-control p-inputSwitch .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
  background: #1400DB;
}

.message-table-control p-inputSwitch .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #1700F4;
}

.message-table-control p-inputSwitch .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: white;
}

.message-table-control p-inputSwitch .p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: 0 0 0 0.2rem #00095B;
}

#message-table-mark-as-read-column .message-table-control p-inputSwitch .p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: 0 0 0 0.2rem white;
}
