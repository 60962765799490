.p-dropdown {
  width: 100%;
}

.p-dropdown-item-group {
  display: flex;
}

.option-group-image-wrapper {
  display: flex;
  width: 15%;
  justify-content: center;
}

.option-group-image {
  max-width: 1.5em;
  max-height: 1.5em;
}

.option-group-label {
  font-weight: 700;
  width: 85%;
  font-family: "Ford Antenna Regular", sans-serif;
  font-size: 16px;
}
