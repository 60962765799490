@import "variables";

.ui-datatable th.ui-state-default.ui-state-active {
	background: #0d73b0;
}

.ui-datatable th.ui-state-default {
	background: $ui-table-bg;
	color: $white;
}

.ui-datatable th.ui-sortable-column:not(.ui-state-active):hover {
	background: $ui-table-bg;
	color: $white;
}

.ui-steps .ui-steps-title {
	font-family: $fontFamilyAntennaCond;
	font-size: 0.9rem;
}
.ui-widget, label, legend, option {
	font-family: $fontFamily;
}

.ui-steps .ui-steps-item .ui-steps-number {
	background-color: #0081c2;
	color: #FFFFFF;
	border-radius: 50%;
}

.ui-steps .ui-steps-item .ui-steps-title {
	color: #555555;
}
.ui-panel .ui-widget-header, .ui-panel-header {
	background: linear-gradient(to bottom, $ui-panel-bg 0%, #cee5f9 100%) !important;
	border-color: $ui-panel-bg;
}
.ui-widget-content {
	border: 1px solid #abd8ec;
}
.ui-panel-footer {
	background-color: #e3f2ff;
}
.ui-panel-footer .ui-button, .ui-panel-footer .ui-button:hover {
	color: #337ab7 !important;
	background: none !important;
	border: 0 !important;
}
.ui-panel-footer .ui-button span, .ui-panel-footer .ui-button:hover span {
	text-decoration: underline;
}
.ui-autocomplete-token{
	font-size: 100%;
}
