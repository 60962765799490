.label-top-space {
  margin-top: 0.5em;
}

.label-left-space {
  margin-left: 0.5em;
}

.label-text-right {
  text-align: right;
}

.label-text-left {
  text-align: left;
}

.control-top-space {
  margin-top: 0.5em;
}

input[type="password"], input.geofence-create-number-input, .p-inputnumber-button-group {
  height: 34px;
}

.password-input-full-width {
  width: 100%;
}

.field-error {
  border-color: red;
}

.field-error-message {
  color: red;
}

.form-field-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5em;
}

.form-field-row-item,
input[type=text].form-field-row-item{
  width: 32%;
}

/* Must be a global style to avoid deprecated ::ng-deep */
.geofence-create-number-input-wrapper {
  width: 100%;
}

.geofence-create-number-input {
  width: calc(100% - 2.357rem);
}

