/* override PrimeNG theme font family */
/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/
/*ui component Colors*/
/*Fonts*/
/*Responsive*/
.ui-datatable th.ui-state-default.ui-state-active {
  background: #0d73b0;
}

.ui-datatable th.ui-state-default {
  background: #215A8F;
  color: #fff;
}

.ui-datatable th.ui-sortable-column:not(.ui-state-active):hover {
  background: #215A8F;
  color: #fff;
}

.ui-steps .ui-steps-title {
  font-family: AntennaCond, Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
}

.ui-widget, label, legend, option {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}

.ui-steps .ui-steps-item .ui-steps-number {
  background-color: #0081c2;
  color: #FFFFFF;
  border-radius: 50%;
}

.ui-steps .ui-steps-item .ui-steps-title {
  color: #555555;
}

.ui-panel .ui-widget-header, .ui-panel-header {
  background: linear-gradient(to bottom, #9fcbf1 0%, #cee5f9 100%) !important;
  border-color: #9fcbf1;
}

.ui-widget-content {
  border: 1px solid #abd8ec;
}

.ui-panel-footer {
  background-color: #e3f2ff;
}

.ui-panel-footer .ui-button, .ui-panel-footer .ui-button:hover {
  color: #337ab7 !important;
  background: none !important;
  border: 0 !important;
}

.ui-panel-footer .ui-button span, .ui-panel-footer .ui-button:hover span {
  text-decoration: underline;
}

.ui-autocomplete-token {
  font-size: 100%;
}

/* override PrimeNG theme font family */
/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/
/*ui component Colors*/
/*Fonts*/
/*Responsive*/
/********************************* [START] Ford Fonts *********************************/
/* This CSS resource incorporates links to font software which is the valuable copyrighted property of WebType LLC, The Font Bureau, Ascender Corporation and/or their suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact WebType with any questions: http://www.webtype.com */
@font-face { /* Ford Antenna Bold */
  font-family: Antenna;
  src: url("../../../fonts/Ford-Antenna-Bold/3fcdf9c8-1bf9-49f4-b9e9-48fba95ab07b-3.woff") format("woff"), url("../../../fonts/Ford-Antenna-Bold/3fcdf9c8-1bf9-49f4-b9e9-48fba95ab07b-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: Antenna;
  src: url("../../../fonts/Ford-Antenna-Medium/16cfc826-eb8a-47b2-8d9b-928e47f8157e-3.woff") format("woff"), url("../../../fonts/Ford-Antenna-Medium/16cfc826-eb8a-47b2-8d9b-928e47f8157e-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face { /* Ford Antenna Regular */
  font-family: Antenna;
  src: url("../../../fonts/Ford-Antenna-Regular/d9e5c1ae-3c12-46d7-97a6-a7ff0861e239-3.woff") format("woff"), url("../../../fonts/Ford-Antenna-Regular/d9e5c1ae-3c12-46d7-97a6-a7ff0861e239-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face { /* Ford Antenna Light */
  font-family: AntennaLight;
  src: url("../../../fonts/Ford-Antenna-Light/11f33cf4-3e86-4d2b-b2df-9e2ae91ba4b5-3.woff") format("woff"), url("../../../fonts/Ford-Antenna-Light/11f33cf4-3e86-4d2b-b2df-9e2ae91ba4b5-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face { /* Ford Antenna Light Italic */
  font-family: AntennaLight;
  src: url("../../../fonts/Ford-Antenna-Light-Italic/2f230fc8-026f-4a1a-82f5-2f116e1e5853-3.woff") format("woff"), url("../../../fonts/Ford-Antenna-Light-Italic/2f230fc8-026f-4a1a-82f5-2f116e1e5853-1.ttf") format("truetype");
  font-style: italic;
  font-weight: 300;
}
@font-face { /* Ford Antenna Condensed Bold */
  font-family: AntennaCond;
  src: url("../../../fonts/Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963-3.woff") format("woff"), url("../../../fonts/Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face { /* Ford Antenna Condensed Medium */
  font-family: AntennaCond;
  src: url("../../../fonts/Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8-3.woff") format("woff"), url("../../../fonts/Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face { /* Ford Antenna Condensed Regular */
  font-family: AntennaCond;
  src: url("../../../fonts/Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd-3.woff") format("woff"), url("../../../fonts/Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
/********************************* [END] Ford Fonts *********************************/
/* override PrimeNG theme font family */
/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/
/*ui component Colors*/
/*Fonts*/
/*Responsive*/
#app-header #app-branding h1 {
  font-weight: 200;
  font-size: 30px;
}

#app-header #app-branding #app-icon {
  color: #425968;
  font-size: 13px;
}

#app-footer > div {
  background-image: linear-gradient(to bottom, #eee 0, #fff 100%);
}

body {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}

/* override PrimeNG theme font family */
/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/
/*ui component Colors*/
/*Fonts*/
/*Responsive*/
@media (min-width: 50px) and (max-width: 479px) {
  #app-header #app-branding #app-name h1 {
    font-size: 18px;
    transition: font-size 0.5s ease-in;
  }
}
@media (min-width: 480px) {
  #app-header #app-branding #app-name h1 {
    font-size: 20px;
    transition: font-size 0.5s ease-in;
  }
}
@media (min-width: 768px) {
  #app-header #app-branding #app-name h1 {
    font-size: 22px;
    transition: font-size 0.5s ease-in;
  }
}
@media (min-width: 960px) {
  #app-header #app-branding #app-name h1 {
    font-size: 24px;
    transition: font-size 0.5s ease-in;
  }
}
@media (min-width: 1024px) and (max-width: 1286px) {
  #app-header #app-branding #app-name h1 {
    font-size: 26px;
    transition: font-size 0.5s ease-in;
  }
}
@media (min-width: 1287px) {
  #app-header #app-branding #app-name h1 {
    font-size: 28px;
    transition: font-size 0.5s ease-in;
  }
}
/* override PrimeNG theme font family */
/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/
/*ui component Colors*/
/*Fonts*/
/*Responsive*/
.ui-menubar {
  background: #215A8F;
}

.ui-state-active {
  background: #428bca;
}

.ui-menubar .ui-menuitem .ui-menuitem-link { /* First Level items */
  color: #fff;
  background: none !important;
  border-radius: 0;
}

.ui-menubar .ui-menuitem .ui-menuitem-link:hover, .ui-menubar .ui-menubar-custom .sidebar-app-login a:hover {
  background-color: #124c85 !important;
}

.ui-menubar .ui-menuitem .ui-menuitem-link:active, .ui-menubar .ui-menubar-custom .sidebar-app-login a:active {
  background-color: #124c85 !important;
  box-shadow: inset 0 0 7px #444;
}

.ui-menubar .ui-menuitem .ui-menuitem-link.ui-state-active {
  background-color: #27405f !important;
  box-shadow: inset 0 0 7px #222;
  transition: background-color 0.5s ease-in;
}

.ui-menubar .ui-menuitem.ui-menuitem-active, .ui-menubar > .ui-menuitem.ui-menuitem-active .ui-menuitem-link { /* First Level items (Active) */
  background: #124c85;
}

.ui-menubar .ui-submenu-list {
  background: #fff;
}

.ui-menubar .ui-submenu-list .ui-menuitem .ui-menuitem-link { /* Second Level items */
  color: #0678bc;
  background: #fff;
  box-shadow: inset 0 0 0 #444;
}

.ui-menubar .ui-submenu-list .ui-menuitem.ui-menuitem-active .ui-menuitem-link { /* Second Level items (Active) */
  background: #eee !important;
  color: #0678bc;
}

.ui-menubar .ui-submenu-list .ui-menuitem.ui-menuitem-active .ui-menuitem .ui-menuitem-link {
  background: #fff;
}

.ui-menubar .ui-submenu-list .ui-menuitem.ui-menuitem-active .ui-menuitem.ui-menuitem-active .ui-menuitem-link {
  background: #eee;
}

.ui-menubar .ui-menu-parent .ui-menu-child {
  background: #fff;
}

.ui-sidebar {
  border: 0;
}

.ui-sidebar .ui-menubar .ui-menuitem .ui-menuitem-link {
  border-bottom: 1px solid #888;
}

.ui-sidebar .ui-menubar .ui-submenu-list .ui-menuitem .ui-menuitem-link {
  border-bottom: 1px solid #ccc;
}

.ui-sidebar-close {
  color: #003478;
  background: #ccc;
}

.btn-sidebar button {
  background: #ccc;
  border: 0;
  outline: none;
  font-size: 1.5em;
  color: #fff;
  border-radius: 20%;
}

.btn-sidebar button:active {
  box-shadow: inset 0 0 15px #fff;
}

.ui-menubar .ui-menubar-custom .sidebar-app-login a {
  color: #fff !important;
  background: none;
  border-radius: 0;
  border-bottom: 1px solid #888;
}

.ui-menubar .ui-menubar-custom .sidebar-app-login a:hover, .ui-menubar .ui-menubar-custom .sidebar-app-login a:active, .ui-menubar .ui-menubar-custom .sidebar-app-login a:focus {
  color: #fff;
}

.ui-menubar .ui-menubar-custom .sidebar-app-login .fa {
  color: #fff;
}

/* override PrimeNG theme font family */
/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/
/*ui component Colors*/
/*Fonts*/
/*Responsive*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: AntennaCond, Arial, Helvetica, sans-serif;
}

h5, h6,
.h5, .h6 {
  font-family: Antenna, Arial, Helvetica, sans-serif;
}

a, a:focus {
  color: #0678bc;
  text-decoration: none;
}

a:hover {
  color: #27405f;
}

hr {
  border: 0;
  border-top: 1px solid #eee;
}

h1,
.h1 {
  font-weight: normal;
  font-size: 2.4rem;
}

h3,
.h3 {
  color: #777;
}

h4,
.h4 {
  color: #444;
}

h5,
.h5 {
  color: #666;
}

#app-footer {
  background-image: linear-gradient(to bottom, #eee 0, #fff 100%);
}

#app-branding h1 {
  font-family: Antenna, Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  color: #036;
}

#app-branding h1 a {
  text-decoration: none;
}
#app-branding h1 a:hover, #app-branding h1 a:focus {
  cursor: pointer;
}

/* override PrimeNG theme font family */
/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/
/*ui component Colors*/
/*Fonts*/
/*Responsive*/
.form-actions {
  border-top: 1px solid #e5e5e5;
}

label, legend {
  font-weight: 700;
}

fieldset label {
  font-weight: 400;
}

textarea {
  font-family: inherit;
}

/* override PrimeNG theme font family */
/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/
/*ui component Colors*/
/*Fonts*/
/*Responsive*/
dt {
  font-weight: 700;
}

/* override PrimeNG theme font family */
/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/
/*ui component Colors*/
/*Fonts*/
/*Responsive*/
.ui-button-link {
  background: none !important;
  border: none;
  outline: none;
  cursor: pointer;
  color: #0678bc;
  text-decoration: none;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  line-height: 1.5;
  font-size: 16px;
}

.ui-button-link:hover {
  color: #27405f;
}