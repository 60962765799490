.mat-menu-content > mat-divider {
  margin: auto;
  width: 90%;
}

.form-header-divider.mat-divider {
  margin: 0.5em 0 1em 0;
  width: 100%;
  border: 1px solid #00095B;
}

.vin-list-button {
  margin-top: 2em;
  flex-wrap: wrap;
}

.vin-list-label {
  font-family: "Ford Antenna Light", sans-serif;
  margin-right: 2em;
  text-align: left;
}

.vin-list-image {
  height: 2.5em;
  margin-right: 2em;
}

.app-header {
  background-color: #00095B;
}

.ui-g {
  display: flex;
  flex-wrap: wrap;
}

.ui-g, .ui-g:after {
  box-sizing: border-box;
}

.ui-g:after {
  clear: both;
  content: "";
  display: table;
}

.ui-g-1, .ui-g-2, .ui-g-3, .ui-g-4, .ui-g-5, .ui-g-6, .ui-g-7, .ui-g-8, .ui-g-9, .ui-g-10, .ui-g-11, .ui-g-12 {
  float: left;
  box-sizing: border-box;
  padding: 0.5em;
}

.ui-g-12 {
  width: 100%;
}

.app-header {
  background-color: #00095B;
}

.app-header {
  background-color: #00095B;
}

#app-content {
  display: flex;
}

.router-content {
  width: 100%;
  margin: 2em;
}

.component-frame {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.app-header {
  background-color: #00095B;
}

.label-top-space {
  margin-top: 0.5em;
}

.label-left-space {
  margin-left: 0.5em;
}

.label-text-right {
  text-align: right;
}

.label-text-left {
  text-align: left;
}

.control-top-space {
  margin-top: 0.5em;
}

input[type=password], input.geofence-create-number-input, .p-inputnumber-button-group {
  height: 34px;
}

.password-input-full-width {
  width: 100%;
}

.field-error {
  border-color: red;
}

.field-error-message {
  color: red;
}

.form-field-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5em;
}

.form-field-row-item,
input[type=text].form-field-row-item {
  width: 32%;
}

/* Must be a global style to avoid deprecated ::ng-deep */
.geofence-create-number-input-wrapper {
  width: 100%;
}

.geofence-create-number-input {
  width: calc(100% - 2.357rem);
}

.p-toast-detail {
  white-space: pre-line;
  overflow-wrap: anywhere;
}

.p-accordion-tab:not(.p-accordion-tab-active) .p-toggleable-content {
  height: 0;
  overflow: hidden;
}

.p-accordion-tab.p-accordion-tab-active .p-toggleable-content {
  height: auto !important;
  overflow: visible;
}

.show-json-summary-panel {
  margin-bottom: 1em;
}

.app-header {
  background-color: #00095B;
}

.p-dropdown {
  width: 100%;
}

.p-dropdown-item-group {
  display: flex;
}

.option-group-image-wrapper {
  display: flex;
  width: 15%;
  justify-content: center;
}

.option-group-image {
  max-width: 1.5em;
  max-height: 1.5em;
}

.option-group-label {
  font-weight: 700;
  width: 85%;
  font-family: "Ford Antenna Regular", sans-serif;
  font-size: 16px;
}

.app-header {
  background-color: #00095B;
}