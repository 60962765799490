@import "variables";

#app-header #app-branding h1 {
	font-weight: 200;
	font-size: 30px;
}
#app-header #app-branding #app-icon{
	color: #425968;
	font-size: 13px;
}
#app-footer > div {
	background-image: linear-gradient(to bottom,$app-footer-bg 0,$white 100%);
}

body{
	font-family: $fontFamily;
}
