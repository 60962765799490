/* primeng doesn't have layout helpers. add them here */
.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}
.items-center{
	display: flex;
	align-items: center;
}