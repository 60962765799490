.p-dialog {
  width: 40%;
}

.progress-spinner {
  position: absolute;
  z-index: 999;
}

circle.p-progress-spinner-circle {
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite, custom-spinner-color 6s ease-in-out infinite;
}

@keyframes custom-spinner-color {
  100%,
  0% {
    stroke: #00095B;
  }
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: 0 0 0 2px #00095B;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #00095B;
  border-color: #00095B;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link,
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:focus .p-accordion-header-link{
  background: #F4F4F4;
  border-color: #C8C8C8;
  color: #333333;
}

.p-accordion-header-link:hover, .p-accordion-header-link:focus {
  text-decoration: none;
}
