@import "variables";

#app-header #app-branding h1 {
	margin: 0;
	line-height: 1.5em;
	display: inline-block;
}

#app-header #app-branding{
	flex-wrap: nowrap;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 1em 0;
}
#app-header #app-branding #app-icon{
	align-self: center;
	text-align: center;
}
#app-header #app-branding #app-name{
	flex-grow: 1;
  padding-left:  1em;
	align-self: center;
}
#app-header #app-branding #app-login{
	float: right;
	text-align: right;
	align-self: center;
}

#app-header #app-branding #app-icon img{
	display: inline-block;
}

#app-header #app-branding #app-login a{
	margin: 0.5em;
}
#app-header #app-branding #app-login a .fa{
	padding-right: .25em;
}

#app-footer > div {
	width: 100%;
}

#app-branding #app-login{
	display:block;
}
.sidebar-app-login, .btn-sidebar{
	display: none;
}

html, body, app-root{
	height: 100%;
}

app-root{
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: stretch;
	align-content: stretch;
}

#app-header{
	flex-shrink: 0;
	flex-basis: auto;
}
