@import "../theme/variables";

/* primeng tables are fixed cell widths. gross. override back to default */
.ui-datatable table {
	table-layout: auto;
}
.ui-datatable table th,
.ui-datatable table td {
	width: auto;
}

.ui-steps {
	margin: .5em 0;
}
.ui-steps .ui-steps-title {
	font-family: $fontFamilyAntennaCond;
	font-size: 0.9rem;
	white-space: normal;
}
.ui-steps .ui-steps-item .ui-menuitem-link{
	height: auto;
	padding: 0 1em;
}
.ui-steps .ui-steps-item.ui-state-highlight, .ui-steps .ui-steps-item.ui-state-default{
	border: none;
	background: none;
}
.ui-steps .ui-steps-item:before{
	display: block;
	content: '';
	border: 5px solid #0678bc;
	position: relative;
	top:1em;
}
.ui-steps .ui-steps-item .ui-steps-number {
	display: inline-block;
	width: 36px;
	margin-top: -14px;
	margin-bottom: 10px;
}

.ui-panel.ui-widget {
	margin-top: 20px;
}
.ui-table table {
	table-layout: auto;
}
.ui-panel-footer {
	padding: 0 1em;
}
.ui-panel-footer button, .ui-panel-footer span {
	padding: .5em;
}
.no-content .ui-panel-content, .no-header .ui-panel-header {
	display: none;
}

