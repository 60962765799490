@import "variables";


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin: 0 0 .5em;
}
p{
	margin: 0 0 10px;
}

hr{
	margin-bottom: 1em;
}
h1,
.h1 {
	margin-bottom:10px;
}
h2,
.h2 {
	margin-bottom:10px;

}
#app-content{
	height: auto;
	flex-grow: 2;
}
#app-footer{
	flex-shrink: 0;
	flex-basis: auto;
	width: 100%;
}
