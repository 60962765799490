@import "variables";


.ui-menubar {
	background: $ui-table-bg;
}
.ui-state-active {
	background: #428bca;
}

.ui-menubar .ui-menuitem .ui-menuitem-link { /* First Level items */
	color: $white;
	background: none !important;
	border-radius: 0;
}
.ui-menubar .ui-menuitem .ui-menuitem-link:hover, .ui-menubar .ui-menubar-custom .sidebar-app-login a:hover{
	background-color: $sidebar-app-login-bg !important;
}
.ui-menubar .ui-menuitem .ui-menuitem-link:active, .ui-menubar .ui-menubar-custom .sidebar-app-login a:active{
	background-color: $sidebar-app-login-bg !important;
	box-shadow: inset 0 0 7px $sidebar-app-login-color;
}
.ui-menubar .ui-menuitem .ui-menuitem-link.ui-state-active{
	background-color: $btn-sidebar-bg !important; //required due to PrimeNG scripted style
	box-shadow: inset 0 0 7px $ui-menubar-box-shadow-color;
	transition: background-color .5s ease-in;
}
.ui-menubar .ui-menuitem.ui-menuitem-active, .ui-menubar > .ui-menuitem.ui-menuitem-active .ui-menuitem-link{ /* First Level items (Active) */
	background: $sidebar-app-login-bg;
}
.ui-menubar .ui-submenu-list{
	background: $white;
}
.ui-menubar .ui-submenu-list .ui-menuitem .ui-menuitem-link { /* Second Level items */
	color: $ui-menuitem-link-color;
	background: $white;
	box-shadow: inset 0 0 0 $sidebar-app-login-color;
}
.ui-menubar .ui-submenu-list .ui-menuitem.ui-menuitem-active .ui-menuitem-link { /* Second Level items (Active) */
	background: $app-footer-bg !important;
	color: $ui-menuitem-link-color;
}

.ui-menubar .ui-submenu-list .ui-menuitem.ui-menuitem-active .ui-menuitem .ui-menuitem-link{
	background: $white;
}
.ui-menubar .ui-submenu-list .ui-menuitem.ui-menuitem-active .ui-menuitem.ui-menuitem-active .ui-menuitem-link{
	background: $app-footer-bg;
}

.ui-menubar .ui-menu-parent .ui-menu-child{
	background: $white;
}
.ui-sidebar{
	border: 0;
}
.ui-sidebar .ui-menubar .ui-menuitem .ui-menuitem-link{
	border-bottom: 1px solid $ui-menubar-border;
}

.ui-sidebar .ui-menubar .ui-submenu-list .ui-menuitem .ui-menuitem-link{
	border-bottom: 1px solid $ui-sidebar-bg;
}
.ui-sidebar-close{
	color: $ford-blue;
	background: $ui-sidebar-bg;
}

.btn-sidebar button{
	background: $ui-sidebar-bg;
	border: 0;
	outline: none;
	font-size: 1.5em;
	color: $white;
	border-radius: 20%;
}

.btn-sidebar button:active{
	box-shadow: inset 0 0 15px $white;
}

.ui-menubar .ui-menubar-custom .sidebar-app-login a{
	color: $white !important; //required due to PrimeNG scripted style
	background: none;
	border-radius: 0;
	border-bottom: 1px solid $ui-menubar-border;
}
.ui-menubar .ui-menubar-custom .sidebar-app-login a:hover, .ui-menubar .ui-menubar-custom .sidebar-app-login a:active, .ui-menubar .ui-menubar-custom .sidebar-app-login a:focus{
	color: $white;
}
.ui-menubar .ui-menubar-custom .sidebar-app-login .fa{
	color: $white;
}
