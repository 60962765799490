/* primeng doesn't have layout helpers. add them here */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.items-center {
  display: flex;
  align-items: center;
}

/* override PrimeNG theme font family */
/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/
/*ui component Colors*/
/*Fonts*/
/*Responsive*/
/* primeng tables are fixed cell widths. gross. override back to default */
.ui-datatable table {
  table-layout: auto;
}

.ui-datatable table th,
.ui-datatable table td {
  width: auto;
}

.ui-steps {
  margin: 0.5em 0;
}

.ui-steps .ui-steps-title {
  font-family: AntennaCond, Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  white-space: normal;
}

.ui-steps .ui-steps-item .ui-menuitem-link {
  height: auto;
  padding: 0 1em;
}

.ui-steps .ui-steps-item.ui-state-highlight, .ui-steps .ui-steps-item.ui-state-default {
  border: none;
  background: none;
}

.ui-steps .ui-steps-item:before {
  display: block;
  content: "";
  border: 5px solid #0678bc;
  position: relative;
  top: 1em;
}

.ui-steps .ui-steps-item .ui-steps-number {
  display: inline-block;
  width: 36px;
  margin-top: -14px;
  margin-bottom: 10px;
}

.ui-panel.ui-widget {
  margin-top: 20px;
}

.ui-table table {
  table-layout: auto;
}

.ui-panel-footer {
  padding: 0 1em;
}

.ui-panel-footer button, .ui-panel-footer span {
  padding: 0.5em;
}

.no-content .ui-panel-content, .no-header .ui-panel-header {
  display: none;
}

.app-header {
  background-color: #00095B;
}

#app-header #app-branding h1 {
  margin: 0;
  line-height: 1.5em;
  display: inline-block;
}

#app-header #app-branding {
  flex-wrap: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1em 0;
}

#app-header #app-branding #app-icon {
  align-self: center;
  text-align: center;
}

#app-header #app-branding #app-name {
  flex-grow: 1;
  padding-left: 1em;
  align-self: center;
}

#app-header #app-branding #app-login {
  float: right;
  text-align: right;
  align-self: center;
}

#app-header #app-branding #app-icon img {
  display: inline-block;
}

#app-header #app-branding #app-login a {
  margin: 0.5em;
}

#app-header #app-branding #app-login a .fa {
  padding-right: 0.25em;
}

#app-footer > div {
  width: 100%;
}

#app-branding #app-login {
  display: block;
}

.sidebar-app-login, .btn-sidebar {
  display: none;
}

html, body, app-root {
  height: 100%;
}

app-root {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
}

#app-header {
  flex-shrink: 0;
  flex-basis: auto;
}

@media (min-width: 50px) and (max-width: 767px) {
  .ui-menubar, #app-branding #app-login {
    display: none;
  }
  .ui-sidebar .ui-menubar, .sidebar-app-login, .btn-sidebar {
    display: block;
  }
  .quicklinks-list li a {
    padding: 0.15em;
  }
  #app-footer * {
    text-align: center;
  }
  #app-branding .logo {
    max-width: 90px;
    transition: max-width 0.5s ease-in;
  }
}
@media (min-width: 50px) and (max-width: 479px) {
  #app-header #app-branding #app-name {
    text-align: center;
  }
  #app-header #app-branding .logo {
    max-width: 95px;
    transition: max-width 0.5s ease-in;
  }
  #app-header #app-branding #app-icon {
    align-self: flex-start;
    padding-top: 0.3em;
  }
}
@media (min-width: 480px) {
  #app-header #app-branding .logo {
    max-width: 100px;
    transition: max-width 0.5s ease-in;
  }
}
@media (min-width: 768px) {
  #app-header #app-branding .logo {
    max-width: 105px;
    transition: max-width 0.5s ease-in;
  }
}
@media (min-width: 960px) {
  #app-header #app-branding .logo {
    max-width: 110px;
    transition: max-width 0.5s ease-in;
  }
}
@media (min-width: 1024px) and (max-width: 1286px) {
  #app-header #app-branding .logo {
    max-width: 115px;
    transition: max-width 0.5s ease-in;
  }
}
@media (min-width: 1287px) {
  #app-header #app-branding .logo {
    max-width: 120px;
    transition: max-width 0.5s ease-in;
  }
}
.ui-menubar {
  border: none;
  padding: 0;
  border-radius: 0;
}

.ui-menubar .ui-menubar-root-list {
  margin: 0;
}

.ui-menubar .ui-menuitem .ui-menuitem-link { /* First Level items */
  text-decoration: none;
  padding: 1em !important;
}

.ui-menubar .ui-submenu-list {
  min-width: 15em;
}

.ui-menubar .ui-submenu-list .ui-menuitem .ui-menuitem-link { /* Second Level items */
  padding: 0.5em !important;
  margin: 0;
}

.ui-menubar .ui-menu-parent .ui-menu-child {
  background: #fff;
}

.ui-sidebar {
  padding: 0;
  overflow-y: auto;
}

.ui-sidebar .ui-menubar .ui-menubar-root-list, .ui-sidebar .ui-menubar .ui-menubar-root-list > .ui-menuitem {
  display: block;
  width: 100%;
}

.ui-sidebar .ui-menubar .ui-submenu-list, .ui-sidebar .ui-menubar .ui-menu-parent .ui-menu-child {
  position: static;
  width: 100%;
}

.ui-sidebar-close {
  z-index: 10000;
  position: absolute;
  right: 0;
  padding: 0.125em 0.25em;
}

.btn-sidebar button {
  padding: 0.25em 0.5em;
}

.ui-menubar .ui-menubar-custom {
  float: none;
  margin: 0;
  padding: 0;
}

.ui-menubar .ui-menubar-custom .sidebar-app-login a {
  display: block;
  width: 100%;
  padding: 0.75em;
}

.app-header {
  background-color: #00095B;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0 0 0.5em;
}

p {
  margin: 0 0 10px;
}

hr {
  margin-bottom: 1em;
}

h1,
.h1 {
  margin-bottom: 10px;
}

h2,
.h2 {
  margin-bottom: 10px;
}

#app-content {
  height: auto;
  flex-grow: 2;
}

#app-footer {
  flex-shrink: 0;
  flex-basis: auto;
  width: 100%;
}

.app-header {
  background-color: #00095B;
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

.form-actions {
  padding: 1em;
  margin: 0 -5px 5px -5px;
}

/* primeng doesn't provide much form styling. add back in */
input[type=text], input[type=number], textarea, select {
  width: 100%;
  padding: 5px;
  font-size: 1em;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}

.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-size: 1em;
}

.ui-autocomplete, .ui-autocomplete .ui-autocomplete-multiple-container.ui-inputtext {
  width: 100%;
}

.ui-autocomplete-token {
  margin: 0.125em;
}

input[type=text], input[type=number] {
  height: 34px;
}

form .ui-dropdown {
  width: 100% !important;
  height: 34px;
  padding: 2px;
}

form .ui-autocomplete, .ui-autocomplete .ui-autocomplete-multiple-container.ui-inputtext {
  width: 100%;
}

.ui-calendar input {
  width: 80%;
}

.app-header {
  background-color: #00095B;
}

.export-btn-group {
  float: right;
}

.export-btn-group > button {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 0;
}

.ui-button-link {
  padding: 0 !important;
}

table .ui-widget-content td:first-child {
  overflow: visible;
}