.ui-menubar {
	border: none;
	padding: 0;
	border-radius: 0;
}
.ui-menubar .ui-menubar-root-list{
	margin: 0;
}
.ui-menubar .ui-menuitem .ui-menuitem-link { /* First Level items */
	text-decoration: none;
	padding: 1em !important;
}
.ui-menubar .ui-submenu-list{
	min-width: 15em;
}
.ui-menubar .ui-submenu-list .ui-menuitem .ui-menuitem-link { /* Second Level items */
	padding: .5em !important;
	margin: 0;
}
.ui-menubar .ui-menu-parent .ui-menu-child{
	background: #fff;
}
.ui-sidebar{
	padding: 0;
	overflow-y: auto;
}
.ui-sidebar .ui-menubar .ui-menubar-root-list, .ui-sidebar .ui-menubar .ui-menubar-root-list>.ui-menuitem{
	display: block;
	width: 100%;
}

.ui-sidebar .ui-menubar .ui-submenu-list, .ui-sidebar .ui-menubar .ui-menu-parent .ui-menu-child{
	position: static;
	width: 100%;
}
.ui-sidebar-close{
	z-index: 10000;
	position: absolute;
	right: 0;
	padding: .125em .25em;
}

.btn-sidebar button{
	padding: .25em .5em;
}
.ui-menubar .ui-menubar-custom{
	float: none;
	margin: 0;
	padding: 0;
}
.ui-menubar .ui-menubar-custom .sidebar-app-login a{
	display: block;
	width: 100%;
	padding: .75em;
}
