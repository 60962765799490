.p-paginator {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  border-radius: 50%;
  margin-left: 0.25em;
  margin-right: 0.25em;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: #00095B;
}

.p-paginator-page.p-link:focus {
  box-shadow: unset;
}

/* Styles specific to the table on the Message Center screen */
#message-table .p-datatable .p-datatable-header {
  color: white;
  background-color: #00095B;
  border: 1px solid #00095B;
  padding-left: 0;
  padding-right: 0;
}

.p-datatable .message-column.p-sortable-column .p-sortable-column-icon,
.p-datatable .message-column p-columnfilter .p-column-filter .p-column-filter-menu-button {
  color: unset;
  text-align: unset;
}

.p-datatable .message-column p-columnfilter .p-column-filter .p-column-filter-menu-button {
  font-weight: bolder;
}

.p-datatable .message-column.p-sortable-column.p-highlight,
.p-datatable .message-column.p-sortable-column.p-highlight .p-sortable-column-icon {
  background: unset;
  color: #1700F4;
}

.p-datatable .message-column.p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #1700F4;
}

.p-datatable .message-column.p-sortable-column.p-highlight:hover {
  box-shadow: inset 0 0 0 0.3rem #1700F4;
}

.p-datatable .message-column p-columnfilter .p-column-filter .p-column-filter-menu-button:hover,
.p-datatable .message-column p-columnfilter .p-column-filter .p-column-filter-menu-button:focus,
.p-datatable .message-column p-columnfilter .p-column-filter .p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-datatable .message-column p-columnfilter .p-column-filter .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background-color: #1700F4;
  color: white;
  box-shadow: unset;
  font-weight: normal;
}

.message-table-control p-inputSwitch .p-inputswitch {
  display: block;
}

.message-table-control p-inputSwitch .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
  background: #1400DB;
}

.message-table-control p-inputSwitch .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #1700F4;
}

.message-table-control p-inputSwitch .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: white;
}

.message-table-control p-inputSwitch .p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: 0 0 0 0.2rem #00095B;
}

#message-table-mark-as-read-column .message-table-control p-inputSwitch .p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: 0 0 0 0.2rem white;
}

.app-header {
  background-color: #00095B;
}

.app-header {
  background-color: #00095B;
}

.app-header {
  background-color: #00095B;
}

.app-header {
  background-color: #00095B;
}

a, a:focus {
  color: #00095B;
}

a:hover {
  text-decoration: underline;
}

.p-toast {
  opacity: 0.98;
}

.form-header {
  font-size: 18px;
  align-self: flex-start;
}

.pi {
  font-weight: inherit;
}

.app-header {
  background-color: #00095B;
}

.control-rounded {
  border-radius: 5px;
}

.control-border {
  border: 1px solid #a6a6a6;
}

label {
  font-family: "Ford Antenna Regular", sans-serif;
  color: #00095B;
}

.p-button {
  background: #00095B;
  border: 1px solid #00095B;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #00095B;
}

.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 2px #00095B;
  border-color: #00095B;
}

.p-inputtext:enabled:hover {
  border-color: #00095B;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 2px #00095B;
  border-color: #00095B;
}

::-ms-reveal {
  display: none;
}

.p-radiobutton .p-radiobutton-box.p-highlight,
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover,
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus,
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #00095B;
  background: #00095B;
}

.p-dialog {
  width: 40%;
}

.progress-spinner {
  position: absolute;
  z-index: 999;
}

circle.p-progress-spinner-circle {
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite, custom-spinner-color 6s ease-in-out infinite;
}

@keyframes custom-spinner-color {
  100%, 0% {
    stroke: #00095B;
  }
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: 0 0 0 2px #00095B;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #00095B;
  border-color: #00095B;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link,
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:focus .p-accordion-header-link {
  background: #F4F4F4;
  border-color: #C8C8C8;
  color: #333333;
}

.p-accordion-header-link:hover, .p-accordion-header-link:focus {
  text-decoration: none;
}

.p-button-label {
  font-family: "Ford Antenna Light", sans-serif;
}

.p-button:enabled:hover, .p-button:enabled:focus {
  background: #1700F4;
  border-color: #1700F4;
  box-shadow: unset;
}

.p-button.p-button-outlined:enabled:hover, .p-button.p-button-outlined:enabled:focus,
.p-column-filter-add-button.p-button:enabled:hover, .p-column-filter-add-button.p-button:enabled:focus {
  background: rgba(0, 122, 217, 0.04);
  border: 1px solid;
}

.p-column-filter-remove-button.p-button:enabled:hover, .p-column-filter-remove-button.p-button:enabled:focus {
  background: rgba(233, 18, 36, 0.04);
  border-color: transparent;
  color: #E91224;
}

.p-dropdown-item {
  font-family: "Ford Antenna Regular", sans-serif;
  font-size: 14px;
  color: #00095B !important;
}