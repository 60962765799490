fieldset {
	padding: 0;
	margin: 0;
	border: 0;
}
.form-actions {
	padding: 1em;
	margin: 0 -5px 5px -5px;
}
/* primeng doesn't provide much form styling. add back in */
input[type="text"], input[type="number"], textarea, select {
	width: 100%;
	padding: 5px;
	font-size: 1em;
	font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}

.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
	font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
	font-size: 1em;
}
.ui-autocomplete, .ui-autocomplete .ui-autocomplete-multiple-container.ui-inputtext{
	width: 100%;
}
.ui-autocomplete-token{
	margin: .125em;
}
input[type="text"], input[type="number"] {
	height: 34px;
}

form .ui-dropdown{
	width: 100% !important;
	height: 34px;
	padding: 2px;
}
form .ui-autocomplete, .ui-autocomplete .ui-autocomplete-multiple-container.ui-inputtext{
	width: 100%;
}

.ui-calendar input{
	width: 80%;
}