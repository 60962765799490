.mat-menu-content > mat-divider {
  margin: auto;
  width: 90%;
}

.form-header-divider.mat-divider {
  margin: 0.5em 0 1em 0;
  width: 100%;
  border: 1px solid #00095B;
}

.vin-list-button {
  margin-top: 2em;
  flex-wrap: wrap;
}

.vin-list-label {
  font-family: "Ford Antenna Light", sans-serif;
  margin-right: 2em;
  text-align: left;
}

.vin-list-image {
  height: 2.5em;
  margin-right: 2em;
}
