a, a:focus{
  color: #00095B;
}

a:hover{
  text-decoration: underline;
}

.p-toast {
  opacity: 0.98;
}

.form-header {
  font-size: 18px;
  align-self: flex-start;
}

.pi {
  font-weight: inherit;
}
