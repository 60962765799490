.p-toast-detail {
  white-space: pre-line;
  overflow-wrap: anywhere;
}

.p-accordion-tab:not(.p-accordion-tab-active) {
  .p-toggleable-content {
    height: 0;
    overflow: hidden;
  }
}

.p-accordion-tab.p-accordion-tab-active {
  .p-toggleable-content {
    height: auto !important;
    overflow: visible;
  }
}

.show-json-summary-panel {
  margin-bottom: 1em;
}
