.ui-g {
  display: flex;
  flex-wrap: wrap;

}

.ui-g,.ui-g:after{
  box-sizing:border-box
}

.ui-g:after {
  clear:both;
  content:"";
  display:table
}

.ui-g-1,.ui-g-2,.ui-g-3,.ui-g-4,.ui-g-5,.ui-g-6,.ui-g-7,.ui-g-8,.ui-g-9,.ui-g-10,.ui-g-11,.ui-g-12 {
  float:left;
  box-sizing:border-box;
  padding:.5em
}

.ui-g-12 {
  width:100%
}
