.control-rounded {
  border-radius: 5px;
}

.control-border {
  border: 1px solid #a6a6a6;
}

label {
  font-family: "Ford Antenna Regular", sans-serif;
  color: #00095B;
}

.p-button {
  background: #00095B;
  border: 1px solid #00095B;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #00095B;
}

.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 2px #00095B;
  border-color: #00095B;
}

.p-inputtext:enabled:hover {
  border-color: #00095B;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 2px #00095B;
  border-color: #00095B;
}

// hide password reveal button in Microsoft Edge
::-ms-reveal {
  display: none;
}

.p-radiobutton .p-radiobutton-box.p-highlight,
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover,
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus,
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #00095B;
  background: #00095B;
}
