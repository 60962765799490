.p-button-label {
  font-family: "Ford Antenna Light", sans-serif;
}

.p-button:enabled:hover, .p-button:enabled:focus {
  background: #1700F4;
  border-color: #1700F4;
  box-shadow: unset;
}

.p-button.p-button-outlined:enabled:hover, .p-button.p-button-outlined:enabled:focus,
.p-column-filter-add-button.p-button:enabled:hover, .p-column-filter-add-button.p-button:enabled:focus
{
  background: rgba(0, 122, 217, 0.04);
  border: 1px solid;
}

.p-column-filter-remove-button.p-button:enabled:hover, .p-column-filter-remove-button.p-button:enabled:focus{
  background: rgba(233, 18, 36, 0.04);
  border-color: transparent;
  color: #E91224;
}
